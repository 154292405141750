import * as React from "react";
import { Modal } from "react-bootstrap";
import { VideoEpisode } from "../../models/video";

interface PropType {
  state: {
    show: {
      set: Function;
      val: boolean;
    };
  };
  episode: VideoEpisode;
}

const LinkDialog: React.FC<PropType> = ({ state, episode }) => {
  return (
    <>
      <Modal
        show={state.show.val}
        fullscreen
        onHide={() => state.show.set(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Episode {episode.ep}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {episode.url.map((el, i) => (
            <div key={i} className="d-grid gap-2">
              <a href={el} className="btn btn-primary" target="_blank">
                Link&nbsp;-&nbsp;{i + 1}
              </a>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LinkDialog;
