import { Spinner } from "react-bootstrap";
import * as React from "react";
import PageHelmet from "./helmet";

const PageLoading: React.FC = () => {
  return (
    <>
      <PageHelmet title="Loading..." />

      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner animation="grow" />
      </div>
    </>
  );
};

export default PageLoading;
