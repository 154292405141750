import * as React from "react";
import { useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import axios from "axios";
import PageHelmet from "../../../components/common/helmet";
import { Button, Card, Container } from "react-bootstrap";
import { VideoEpisode, VideoModel } from "../../../models/video";
import PageLoading from "../../../components/common/pageLoading";
import LinkDialog from "../../../components/video/linkDialog";

const WatchVideo: React.FC<RouteComponentProps> = () => {
  const [video, setVideo] = useState<VideoModel>();
  const [showUrlDialog, setShowUrlDialog] = useState(false);
  const [episode, setEpisode] = useState<VideoEpisode>();

  const params = useParams();

  const fetchVideo = async () => {
    const { title } = params;
    const url = `https://daussho.my.id/public/video/${title}.json`;
    try {
      const data: VideoModel = await axios.get(url).then((r) => r.data);
      setVideo(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchVideo();
  }, []);

  if (!video) return <PageLoading />;

  return (
    <>
      <PageHelmet title={video.title} />
      <Container>
        <Card>
          <Card.Img variant="top" src={video.img} />
          <Card.Body>
            <Card.Title>{video.title}</Card.Title>
            <Card.Text>{video.desc}</Card.Text>
          </Card.Body>
          {video.episode.map((el, i) => (
            <Button
              key={i}
              className="m-1"
              disabled={el.url.length === 0}
              onClick={() => {
                setEpisode(el);
                setShowUrlDialog(true);
              }}
            >
              Episode {el.ep}
            </Button>
          ))}
        </Card>
      </Container>

      {showUrlDialog && (
        <LinkDialog
          state={{
            show: {
              set: setShowUrlDialog,
              val: showUrlDialog,
            },
          }}
          episode={episode}
        />
      )}
    </>
  );
};

export default WatchVideo;
