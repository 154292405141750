import { RouteComponentProps, Router } from "@reach/router";
import * as React from "react";
import WatchVideo from "../../templates/pages/video/watch";

const Home: React.FC<RouteComponentProps> = () => {
  return <div>Home</div>;
};

const Video: React.FC = () => {
  return (
    <>
      <Router basepath="/video">
        <WatchVideo path="/:title" />
        <Home path="/" />
      </Router>
    </>
  );
};

export default Video;